<template>
    <div>
        <!-- 顶部 -->
        <el-row>
            <el-col :span='24'>
                <el-form :inline='true'>
                    <el-form-item label='题型:'>
                        <el-select v-model="filters.questionsType" placeholder="请选择" clearable @change='getExaminationQuestionsListPage'>
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label='难度:'>
                        <el-select v-model="filters.difficulty" placeholder="请选择" clearable @change='getExaminationQuestionsListPage'>
                            <el-option
                                v-for="item in options1"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label='启用标识:'>
                        <el-select v-model="filters.isEnable" placeholder="请选择" clearable @change='getExaminationQuestionsListPage'>
                            <el-option
                                v-for="item in options2"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label='关键字:'>
                        <el-input v-model='filters.questionsTitle' placeholder='请输入题目' clearable @clear='getExaminationQuestionsListPage' @input='() => (filters.questionsTitle = filters.questionsTitle.replace(/\s/g,""))' @keyup.enter.native='getExaminationQuestionsListPage'></el-input>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <!-- 列表 -->
        <el-table
        border
        :data='tableData'
        highlight-current-row
        @current-change="selectCurrentRow"
        v-loading='listLoading'
        style='width:95%'>
            <el-table-column prop='QuestionsCode' width='100' align='center' label='试题编号'></el-table-column>
            <el-table-column prop='QuestionsType' width='' align='center' label='试题类型'>
                <template slot-scope='scope'>
                    <el-tag v-if='scope.row.QuestionsType == 0' type='primary'>单选题</el-tag>
                    <el-tag v-if='scope.row.QuestionsType == 1' type='warning'>多选题</el-tag>
                    <el-tag v-if='scope.row.QuestionsType == 2' type='danger'>判断题</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop='QuestionsTitle' width='' align='center' label='试题题目' show-overflow-tooltip></el-table-column>
            <el-table-column prop='Difficulty' width='' align='center' label='试题难度'>
                <template slot-scope='scope'>
                    <el-tag v-if='scope.row.Difficulty == 0' type='danger'>高</el-tag>
                    <el-tag v-if='scope.row.Difficulty == 1' type='warning'>中</el-tag>
                    <el-tag v-if='scope.row.Difficulty == 2' type='primary'>低</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop='Analysis' width='' align='center' label='试题解析' show-overflow-tooltip></el-table-column>
            <el-table-column prop='IsEnable' width='' align='center' label='启用标识'>
                <template slot-scope='scope'>
                    <el-tag :type='scope.row.IsEnable?"success":"warning"'>{{scope.row.IsEnable?"启用":"禁用"}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop='Remarks' width='' align='center' label='备注' show-overflow-tooltip></el-table-column>
            <el-table-column prop='CreateTime' width='165' align='center' label='创建时间' :formatter="formatCreateTime"></el-table-column>
            <el-table-column prop='CreateUserName' width='' align='center' label='创建人' show-overflow-tooltip></el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pages.pageIndex"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pages.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pages.dataCount">
        </el-pagination>
        <!-- 新增/编辑 -->
        <el-dialog
        :title="isAdd?'新增':'编辑'"
        :visible.sync="dialogVisible"
        :close-on-click-modal='false'
        width="50%">
        <el-form :model="form" :rules="rules" ref="formRef" label-width="100px">
            <el-form-item label='试题题目:' prop='questionsTitle'>
                <el-input v-model='form.questionsTitle' type='textarea' placeholder='请输入题目' @input='() => (form.questionsTitle = form.questionsTitle.replace(/\s/g,""))'></el-input>
            </el-form-item>
            <el-form-item label='试题难度:' prop='difficulty'>
                <el-radio-group v-model="form.difficulty">
                    <el-radio :label="item.value" v-for='item in options1' :key='item.value'>{{item.label}}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label='试题类型:' prop='questionsType'>
                <el-select v-model="form.questionsType" placeholder="请选择" :disabled='!isAdd?true:false'>
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <!--  -->
            <el-row justify="center" align="middle" class="checked" v-if="form.questionsType != 2">
                <el-col :span="12"><p>选项文字</p></el-col>
                <el-col :span="4"><p>答案</p></el-col>
                <el-col :span="8"><p>操作</p></el-col>
            </el-row>
            <!-- 单选/多选 -->
            <template v-if='form.questionsType != 2'>
                <el-row v-for='(item,index) in form.options' :key='item.Letter'>
                    <el-col :span='12'>
                        <el-form-item label-width="20px" prop='answerContent'>
                            <el-input placeholder="请输入内容" v-model="item.answerContent">
                                <template slot="prepend">{{item.letter}}</template>
                            </el-input>
                        </el-form-item>                       
                    </el-col>
                    <el-col :span='4'>
                        <el-form-item label-width='50%' prop='isAnswer'>
                            <el-radio v-model="finalAnswer" :label="item.answer" v-if='form.questionsType == 0'>{{item.judgeAnswer}}</el-radio>
                            <el-checkbox-group v-model="checkList" v-if='form.questionsType == 1'>
                                <el-checkbox :label="item.answer">{{item.judgeAnswer}}</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>                       
                    </el-col>
                    <el-col :span='8'>
                        <el-row>
                            <el-col :span='6'><el-button circle size='mini' icon='el-icon-plus' type='primary' @click='add(index)'></el-button></el-col>
                            <el-col :span='6'><el-button circle size='mini' icon='el-icon-minus' type='primary' @click='reduce(index)'></el-button></el-col>
                            <el-col :span='6'><el-button circle size='mini' icon='el-icon-arrow-down' type='primary' @click='down(index)'></el-button></el-col>
                            <el-col :span='6'><el-button circle size='mini' icon='el-icon-arrow-up' type='primary' @click='up(index)'></el-button></el-col>
                        </el-row>                       
                    </el-col>
                </el-row>
            </template>
            <!-- 判断 -->
            <template v-if='form.questionsType == 2'>
                <el-row class="judge">
                    <el-col :span="4" >
                        <el-form-item label="答案:" prop="answers" >
                            <el-radio-group v-model="finalJudgeAnswer" class="answer">
                                <el-radio :label="0">正确</el-radio>
                                <el-radio :label="1">错误</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
            </template>
            <!--  -->
            <el-form-item label='试题解析:' prop='analysis'>
                <el-input v-model='form.analysis' type='textarea' placeholder='请输入解析'></el-input>
            </el-form-item>
            <el-form-item label='备注:' prop='remarks'>
                <el-input v-model='form.remarks' type='textarea' placeholder='请输入备注'></el-input>
            </el-form-item>
            <el-form-item label='启用标识:' prop='isEnable'>
                <el-switch v-model="form.isEnable" active-color="#13ce66" inactive-color="#dcdfe6"></el-switch>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="submit" :loading='dialoagLoading'>确 定</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
import { getButtonList } from "../../promissionRouter";
import Toolbar from '../../components/Toolbar.vue';
import util from "../../../util/date";
import Qs from 'qs';
import {getExaminationQuestionsListPage,addExaminationQuestions,updateExaminationQuestions,deleteExaminationQuestions} from '@/api/api'
export default {
    components:{Toolbar},
    data(){
        return {
            buttonList:[],
            options:[
                {label:'单选题',value:0},
                {label:'多选题',value:1},
                {label:'判断题',value:2}
            ],
            options1:[
                {label:'高',value:0},
                {label:'中',value:1},
                {label:'低',value:2}
            ],
            options2:[
                {label:'启用',value:true},
                {label:'禁用',value:false}
            ],
            filters:{
                questionsType:'',
                difficulty:'',
                questionsTitle:'',
                isEnable:''
            },
            currentRow:{},
            tableData:[],
            pages:{
                pageIndex:1,
                pageSize:20,
                dataCount:0
            },
            listLoading:false,
            isAdd:true, // 是否新增
            // 表单数据
            form:{
                questionsCode:'',
                questionsTitle:'',
                questionsType:0,
                difficulty:0,
                analysis:'',
                isEnable:true,
                options:[
                    {
                       answerContent:"",
                       isAnswer:false,
                       letter:'A',
                       answer:0
                    }
                ],
                remarks:'',
            },
            rules:{
                questionsCode:[{}],
                questionsTitle:[{required:true,trigger:'blur',message:'请输入试题题目'}],
                questionsType:[{required:true,trigger:'blur',message:'请选择试题类型'}],
                difficulty:[{required:true,trigger:'blur',message:'请选择试题难度'}],
                analysis:[{required:true,trigger:'blur',message:'请输入试题解析'}],
                remarks:[{}]
            },
            dialogVisible:false,
            dialoagLoading:false,
            finalAnswer:'', // 单选最终答案
            checkList:[], // 多选最终答案
            finalJudgeAnswer:'', // 判断最终答案
        }
    },
    watch:{},
    methods:{
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        //时间格式化
        formatCreateTime: function(row, column) {
            return !row.CreateTime || row.CreateTime == ""
            ? ""
            : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd hh:mm:ss");
        },
        // 选择当前行
        selectCurrentRow(row){
            this.finalAnswer = ''
            this.checkList = []
            this.finalJudgeAnswer = ''
            this.currentRow = row
        },
        // 分页
        handleSizeChange(value){
            this.pages.pageSize = value
            this.getData()
        },
        handleCurrentChange(value){
            window.sessionStorage.setItem('pagination',JSON.stringify(value))
            this.pages.pageIndex = value
            this.getData()
        },
        getData(){
            var params = {
                questionsType:this.filters.questionsType,
                difficulty:this.filters.difficulty,
                questionsTitle:this.filters.questionsTitle,
                isEnable:this.filters.isEnable,
                pageIndex:this.pages.pageIndex,
                pageSize:this.pages.pageSize
            }
            this.listLoading = true
            getExaminationQuestionsListPage(params).then(res =>{
                this.listLoading = false
                if(res.data.Success){
                    this.tableData = res.data.Response.Data
                    this.pages.dataCount = res.data.Response.DataCount
                }else{
                    this.$message.error(res.data.Message)
                }
            }).catch(() =>{this.listLoading = false})
        },
        // 表单提交
        submit(){
            var num = 1
            if(this.form.questionsType !== 2){
                this.form.options.forEach(item =>{
                    if(!item.answerContent ){
                        num++
                    }
                })
            }else if(this.form.questionsType === 2){
                if(this.finalJudgeAnswer != 0 && this.finalJudgeAnswer != 1){
                    num++
                }else{
                    num=1
                }
            }
            
            ////// 有问题
            if(num != 1){
                this.$message.warning('选项内容请补充完整')
                num = 1
            }else{
                if(this.isAdd){
                    this.addSubmit()
                }else{
                    // 单选
                    if(this.form.questionsType == 0){
                        if(this.finalAnswer === ''){
                            this.$message.warning('请选择试题答案')
                        }else{
                            this.form.options.forEach(item =>{
                                if(item.answer == this.finalAnswer){
                                    item.isAnswer = true                           
                                }else{
                                    item.isAnswer = false
                                }
                            })
                            var params = {...this.form}                
                        }               
                    }
                    // 多选数据
                    if(this.form.questionsType == 1){
                        if(this.checkList.length){
                            this.form.options.forEach(item => {
                                item.isAnswer = false
                                this.checkList.forEach(i => {
                                    if(item.answer == i){
                                        item.isAnswer = true
                                    }                                  
                                })
                            })
                            var params = {...this.form}
                        }else{
                            this.$message.warning('请选择试题答案')
                        }              
                    }
                    // 判断数据
                    if(this.form.questionsType == 2){
                        if(this.finalJudgeAnswer===''){
                            this.$message.warning('请选择试题答案')
                        }else{
                            var a = this.form.options.find(item => {
                                if(this.finalJudgeAnswer == 0){
                                    if(item.answer == this.finalJudgeAnswer){
                                        item.answerContent = '正确'
                                        item.isAnswer = true
                                    }else{
                                        item.answerContent = '错误'
                                        item.isAnswer = false
                                    }
                                }else{
                                    if(item.answer == this.finalJudgeAnswer){
                                        item.answerContent = '错误'
                                        item.isAnswer = true
                                    }else{
                                        item.answerContent = '正确'
                                        item.isAnswer = false
                                    }
                                }                       
                            })
                            var params = {...this.form}
                        }               
                    }
                    this.editSubmit(params)
                }               
            }
        },
        addSubmit(){
            // 单选数据
            if(this.form.questionsType == 0){
                if(this.finalAnswer === ''){
                    this.$message.warning('请选择试题答案')
                }else{
                    this.form.options.map(item =>{
                        if(item.answer == this.finalAnswer){
                            item.isAnswer = true                           
                        }else{
                            item.isAnswer = false
                        }
                    })
                    var params = {...this.form}                
                }
                
            }
            // 多选数据
            if(this.form.questionsType == 1){
                if(this.checkList.length){
                    this.checkList.forEach(item =>{
                        this.form.options.map((i,index) => {
                            if(item == i.answer){
                                i.isAnswer = true
                            }
                        })
                    })
                    var params = {...this.form}
                }else{
                    this.$message.warning('请选择试题答案')
                }              
            }
            // 判断数据
            if(this.form.questionsType == 2){
                if(this.finalJudgeAnswer===''){
                    this.$message.warning('请选择试题答案')
                }else{
                    this.form.options.push({
                        answerContent:'',
                        isAnswer:false,
                        letter:'B',
                        answer:1
                    })
                    var a = this.form.options.find(item => {
                        if(this.finalJudgeAnswer == 0){
                            if(item.answer == this.finalJudgeAnswer){
                                item.answerContent = '正确'
                                item.isAnswer = true
                            }else{
                                item.answerContent = '错误'
                                item.isAnswer = false
                            }
                        }else{
                            if(item.answer == this.finalJudgeAnswer){
                                item.answerContent = '错误'
                                item.isAnswer = true
                            }else{
                                item.answerContent = '正确'
                                item.isAnswer = false
                            }
                        }                       
                    })
                    var params = {...this.form}
                }               
            }
            if(params){
                this.dialoagLoading = true
                addExaminationQuestions(params).then(res =>{
                    this.dialoagLoading = false
                    this.dialogVisible = false
                    if(res.data.Success){
                        this.$message.success(res.data.Message)
                        this.finalAnswer = ''
                        this.checkList = []
                        this.finalJudgeAnswer == ''
                        this.getData()
                    }else{
                        this.$message.error(res.data.Message)
                        this.finalAnswer = ''
                        this.checkList = []
                        this.finalJudgeAnswer === ''
                    }
                }).catch(() =>{
                    this.dialoagLoading = false
                    this.dialogVisible = false
                })
            }else{return}           
        },
        editSubmit(params){
            params.id = this.currentRow.ID
            this.dialoagLoading = true
            updateExaminationQuestions(params).then(res => {
                this.dialogVisible = false
                this.dialoagLoading = false
                if(res.data.Success){
                    this.$message.success(res.data.Message)
                    this.getData()
                }else{
                    this.$message.success(res.data.Message)
                }
            }).catch(() =>{
                this.dialogVisible = false
                this.dialoagLoading = false
            })
        },
        // 查询
        getExaminationQuestionsListPage(){
            this.pages.pageIndex = 1
            this.getData()
        },
        // 新增
        handleAdd(){
            this.isAdd = true
            this.form = {
                questionsCode:'',
                questionsTitle:'',
                questionsType:0,
                difficulty:0,
                analysis:'',
                isEnable:true,
                options:[
                    {
                       answerContent:"",
                       isAnswer:false,
                       letter:'A',
                       answer:0
                    }
                ],
                remarks:'',
            }
            this.finalAnswer = ''
            this.finalJudgeAnswer === ''
            this.checkList = []
            
            this.dialogVisible = true
        },
        // 编辑
        handleEdit(){
            var row = this.currentRow
            if(JSON.stringify(row) == '{}'){
                this.$message.warning('请选择要编辑的一行数据')
            }else{
                this.isAdd = false
                // 编辑回显操作
                this.form = {
                    questionsCode:row.QuestionsCode,
                    questionsTitle:row.QuestionsTitle,
                    questionsType:row.QuestionsType,
                    difficulty:row.Difficulty,
                    analysis:row.Analysis,
                    isEnable:row.IsEnable,
                    options:row.Options,
                    remarks:row.Remarks
                }               
                var letterArr = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']
                this.form.options = this.form.options.map((item,index) =>{
                    var obj = {}
                    obj.answerContent = item.AnswerContent
                    obj.isAnswer = item.IsAnswer
                    obj.letter = letterArr[index]
                    obj.answer = index
                    return obj
                })
                this.form.options.forEach(item =>{
                    if(item.isAnswer == true){
                        if(row.QuestionsType == 0){
                            this.finalAnswer = item.answer
                        }
                        if(row.QuestionsType == 1){
                            this.checkList.push(item.answer)
                        }
                        if(row.QuestionsType == 2){
                            this.finalJudgeAnswer = item.answer
                        }
                    }
                })
                this.dialogVisible = true
            }
        },
        // 删除
        handleDel(){
            var row = this.currentRow
            if(JSON.stringify(row) == '{}'){
                this.$message.warning('请选择要删除的一行数据')
            }else{
                this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(()=>{
                    var params = {id:row.ID}
                    deleteExaminationQuestions(Qs.stringify(params)).then(res => {
                        if(res.data.Success){
                            this.$message.success(res.data.Message)
                            this.getData()
                        }else{
                            this.$message.error(res.data.Message)
                        }
                    }).catch(() =>{})
                }).catch(() =>{})
            }
        },
        // 答案事件处理
        add(){
            var letterArr = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']
            var obj = {
                answerContent:'',
                isAnswer:false,
                letter:letterArr[this.form.options.length],
                answer:this.form.options.length
            }
            this.form.options.push(obj)
        },
        reduce(index){
            if(this.form.options.length==1){
                return this.$message.warning('不能再减了')
            }else{
                var letterArr = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']
                this.form.options.splice(index,1)
                for(var i = 0; i < this.form.options.length; i++){
                    this.form.options[i].answer = i
                    this.form.options[i].letter = letterArr[i]
                }
            }
        },
        down(index){
            if(index != this.form.options.length - 1){
                var value = this.form.options[index].answerContent
                var value1 = this.form.options[index+1].answerContent
                this.form.options[index+1].answerContent = value
                this.form.options[index].answerContent = value1
            }else{
                return this.$message.warning('不能下移了')
            }           
        },
        up(index){
            if(index != 0){
                var value = this.form.options[index].answerContent
                var value1 = this.form.options[index-1].answerContent
                this.form.options[index-1].answerContent = value
                this.form.options[index].answerContent = value1
            }else{
                return this.$message.warning('不能上移了')
            }
        }
    },
    beforeRouteEnter(to,from,next){
        if(from.path.indexOf(to.path) == -1){
            window.sessionStorage.removeItem('pagination')
            next()
        }
    },
    created(){
        this.pages.pageIndex = Number(window.sessionStorage.getItem('pagination')) || 1
        this.getData()
    },
    mounted(){
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>
    .title{
        text-align:center;
    }
    .checked .el-col p{
        display:inline-blocck!important;
        text-align:center!important;
    }
    .answer{
        display:flex!important;
        align-items :center;
        height:45px;
    }
</style>
<style>
    .el-tooltip__popper {
        max-width: 500px;
    }
</style>